<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="mb-0">Dados do Serviço</h3>
          </div>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group  row">
              <div class="form-group  col-md-12">
                <label class="col-md-12">Nome:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.nome"
                  placeholder="Digite o Nome"
                />
              </div>
            </div>
            
            
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:*</label>
                <textarea
                
                  required
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite a descrição"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="confirm(!lista_campos.id ? 'cria' : 'edita')"
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Serviços" }]);
  },
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        descricao: null,
      },

      verif: false,
      currentPage: 1,
      perPage: 10,
      editarProduto: true,
    };
  },
  created() {
    this.preenxerCampos()
  },
  computed: {

    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.servico.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Produto no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("servico/create_servico", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "servicos",
      });
    },
    async update() {
        this.verif = true;
      await this.$store.dispatch("servico/update_servico", this.form);
      this.verif = false;

        this.fireAlert({
        ...this.mensagem_alert,
        routeName: "servicos",
      });
    },

  
    async preenxerCampos() {
      console.log(this.lista_campos);

    if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          descricao: this.lista_campos.descricao,

        };
    }
    }
    
  

};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>